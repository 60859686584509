#testimonials {
    padding-bottom: 0;
}

.swiper {
    height: fit-content;  
}

.swiper-slide {
    padding-top: 1rem;
    cursor: grab;
    padding-bottom: 12rem;
}

.swiper-pagination {
    margin-bottom: 8rem;
}

.swiper-pagination-bullet {
    background: var(--color-primary)
}

.testimonial__client {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1.5rem;
}

.testimonial__client-avatar {
    width: 2.5rem;
    aspect-ratio: 1/1;
    border-radius: var(--border-radius-2);
    overflow: hidden;
    transition: var(--transition);
}

.card.testimonial {
    background: var(--color-white);
    max-width: 50vw;
    margin-left: auto;
    margin-right: auto;
}

.card.testimonial:hover {
    border-color: var(--color-white);
}

/* MEDIA QUERIES (phones) */
@media screen and (max-width: 600px) {
    .card.testimonial {
        max-width: 100vw;
    }
}