#backdrop {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    backdrop-filter: blur(5px);
    z-index: 99;
    opacity: 0;
    animation: animateBackdrop 500ms ease-in forwards;
}

@keyframes animateBackdrop {
    to {
        opacity: 1;
    }
}