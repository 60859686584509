.experiences__container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 4rem;
    align-items: center;

}

.card.experience {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    justify-content: space-between;
    width: 60vw;
}

.experience:hover {
    border-color: var(--color-white);
}

.experience__header {
    text-align: center;
    margin-bottom: 3rem;
}

.experience__list {
    list-style: square outside;
    color: var(--color-black);
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    width: 40vw;
}

.experience li {
    margin-bottom: 1.5rem;
}

.experience h4 {
    margin-bottom: 0.7rem;
}

.experience h2 {
    margin-bottom: 2rem;
}

.swiper {
    height: fit-content;
}

.swiper-slide {
    padding-top: 1rem;
    cursor: grab;
    padding-bottom: 12rem;
    width: 80vw;
}

.swiper-pagination {
    margin-bottom: 8rem;
}

.swiper-pagination-bullet {
    background: var(--color-primary)
}

@media screen and (max-width: 600px) {

    .card.experience {
        width: 80vw;
    }

    .experience__list {
        width: 70vw;
    }
}
